import { retrieveFeatureFlags } from "./feature-flags";

var analytics = (window.analytics = window.analytics || []);

if (analytics.invoked) {
  if (process.env.NODE_ENV !== "production") {
    // eslint-disable-next-line no-console
    console.error("Segment snippet included twice.");
  }
} else {
  analytics.invoked = !0;
  analytics.methods = [
    "trackSubmit",
    "trackClick",
    "trackLink",
    "trackForm",
    "pageview",
    "identify",
    "reset",
    "group",
    "track",
    "ready",
    "alias",
    "debug",
    "page",
    "once",
    "off",
    "on",
  ];

  analytics.factory =
    (t) =>
    (...args) => {
      var e = Array.prototype.slice.call(args);
      e.unshift(t);
      analytics.push(e);
      return analytics;
    };

  for (var t = 0; t < analytics.methods.length; t++) {
    var e = analytics.methods[t];
    analytics[e] = analytics.factory(e);
  }

  analytics.load = function (t, e) {
    var n = document.createElement("script");
    n.type = "text/javascript";
    n.async = !0;
    n.src = `https://cdn.segment.com/analytics.js/v1/${t}/analytics.min.js`;
    var a = document.getElementsByTagName("script")[0];
    a.parentNode.insertBefore(n, a);
    analytics._loadOptions = e;
  };

  analytics.SNIPPET_VERSION = "4.1.0";
}

export const initializeSegment = () => {
  if (!analytics.initialize) {
    analytics.load(process.env.SEGMENT_ID);
  }
};

export const page = (...params) => window.analytics.page.apply(null, params);
export const identify = (...params) =>
  window.analytics.identify.apply(null, params);
export const track = (...params) => {
  // Add additional properties
  params[1] = params[1] || {};
  params[1].fromURL = window.location.href;
  params[1].featureFlags = retrieveFeatureFlags();

  return window.analytics.track.apply(null, params);
};
