export const featureFlagNames = {
  homepageOptimisationPhase1: "homepage_optimisation_phase_1",
};

type FeatureFlagsType = {
  [key: string]: string | boolean | number | FeatureFlagsType;
};

export const setFeatureFlags = (flags: FeatureFlagsType): void =>
  sessionStorage.setItem("featureFlags", JSON.stringify(flags));

export const retrieveFeatureFlags = (): FeatureFlagsType => {
  const flags = sessionStorage.getItem("featureFlags");
  try {
    return flags ? JSON.parse(flags) : {};
  } catch (err) {
    return {};
  }
};
